import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useWeb3Modal } from '@web3modal/wagmi/react';

import { useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi';
// import { useWeb3Modal } from '@web3modal/react';
import { getNetwork } from '@wagmi/core';

import { SiweMessage } from 'siwe';

import axios from 'axios';


export default function Profile({ type }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const enterTimes = searchParams.get('enterTimes');

    const [theAddress, setTheAddress] = useState();
    const [theChainId, setTheChainId] = useState();

    const { open } = useWeb3Modal();


    const { isConnected } = useAccount({
        onConnect({ address }) {
            const { chain } = getNetwork();
            setTheAddress(address);
            setTheChainId(chain.id);
        },
    });

    const { open: openWeb3Modal } = useWeb3Modal();

    // 目前在 App 中設定的 connectors 只有一個 walletConnect
    const { connectAsync, connectors, error } = useConnect();
    const { disconnect } = useDisconnect();
    const [state, setState] = useState({ loading: false, nonce: '' });
    const { signMessageAsync } = useSignMessage();

    const callbackApp = ({ address, newToken, errorMessage }) => {
        try {
            const callbackData = { walletAddress: address, newToken, errorMessage };
            console.log({ callbackData });
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify(callbackData));
            }
        } catch (error) {
            alert(error);
        }
        // 簽署登入後必須斷線
        disconnect();
    };

    const handleClose = () => {
        // 回傳空值即可關閉視窗
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({}));
        }
    };

    const fetchNonce = async () => {
        try {
            const { data } = await axios('/api/v1/auth/wallet/nonce');
            const nonce = data;
            console.log({ nonce });
            setState((x) => ({ ...x, nonce }));

            return nonce;
        } catch (error) {
            setState((x) => ({ ...x, error }));
        }
    };

    const onSuccess = async ({ address, newToken }) => {
        setState((x) => ({ ...x, address }));
        console.log({ address, newToken });
        callbackApp({ address, newToken });
    };

    const onError = ({ error }) => {
        setState((x) => ({ ...x, error }));
        console.error({ error });
        callbackApp({ errorMessage: error.message });
    };

    const onConnectWallet = async (walletIndex = 0) => {
        if (!isConnected) {
            try {
                await openWeb3Modal();
            } catch (error) {
                // 可能已連線
                console.error(error);
            }
        }
    };

    const onSigninWallet = async () => {
        // 登入改成不用簽署，直接 connect 即可
        await verifyAddress(theAddress, theChainId);
    };


    // 改成一開始就發動 connect
    useEffect(() => {
        onConnectWallet(0);
    }, []);

    useEffect(() => {
        if (enterTimes > 0) {
            onConnectWallet(0);
        }
    }, [enterTimes]);

    useEffect(() => {
        console.log('error:' + error);
        if (error && error.message) {
            onError({ error });
        }
    }, [error]);

    useEffect(() => {
        try {
            if (!theAddress || !theChainId) {
                return;
            }

            // 登入改成不用簽署，直接 connect 即可
            // 若只是連線，回傳 address 即可
            if (type === 'connect') {
                onSuccess({ address: theAddress });
                setTheAddress('');
                setTheChainId('');

                return;
            }

            // // 登入改成不用簽署，直接 connect 即可
            // await verifyAddress(theAddress, theChainId);
        } catch (error) {
            setState((x) => ({ ...x, loading: false, nonce: undefined }));
            onError({ error });
            console.error(error);
        }
    }, [theAddress, theChainId]);

    return (
        <div className="outer">
            <div className="inner">
                <div className="confirm  c555">
                    <div className="wallet-wrap">
                        {!isConnected && (
                            <div
                                className="wallet-btn"
                                onClick={() => {
                                    open();
                                }}
                            >
                                <div className="img-box">
                                    <img
                                        src="/images/connect.png"
                                        alt="eth_b"
                                        layout="fill"
                                        objectFit="contain"
                                        objectPosition="center"
                                    />
                                </div>
                                <div className="title bd4-b">walletConnect</div>
                                <div className="bd3-b">ETH</div>
                            </div>
                        )}

                        {isConnected && (
                            <div className="btn-wallet-box" style={{ height: '90%' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="icon-box">
                                        <img
                                            src="/images/connect.png"
                                            alt="eth_b"
                                            layout="fill"
                                            objectFit="contain"
                                            objectPosition="center"
                                        />
                                    </div>
                                    <div className="title">WalletConnect</div>
                                    <div className="title2">錢包已成功連接</div>
                                    <p className="content">
                                        為了確保您的帳戶安全與隱私資訊，需要請您簽署錢包授權來進行登入作業，這是一項重要的安全措施，保護帳戶不受未經授權的訪問或不當使用。
                                    </p>
                                </div>
                                <button className="loginBtn" onClick={() => onSigninWallet(0)}>
                                    簽署授權登入
                                </button>
                            </div>
                        )}

                        {/* 測試用，平時關閉 */}
                        {/* <div
                            className="btn-wallet-box jc-between"
                            onClick={() => {
                                onConnectWallet(1);
                            }}
                        >
                            <div className="d-flex ai-center">
                                <div className="icon-box">
                                    <img
                                        src="/images/connect.svg"
                                        alt="eth_b"
                                        layout="fill"
                                        objectFit="contain"
                                        objectPosition="center"
                                    />
                                </div>
                                <div className="title bd4-b">MetaMask</div>
                            </div>
                            <div className="bd3-b">ETH</div>
                        </div> */}
                    </div>
                    <div className="errorBox">{error && <div>{error.message}</div>}</div>

                    <button className="close-btn" onClick={handleClose}>
                        取消
                    </button>
                </div>
            </div>
        </div>
    );

    async function verifyAddress(theAddress, theChainId) {
        try {
            const nonce = await fetchNonce();

            const message = new SiweMessage({
                domain: window.location.host,
                address: theAddress,
                statement: 'Sign in with Ethereum to the app.',
                uri: window.location.origin,
                version: '1',
                chainId: theChainId,
                nonce: nonce,
            });
            console.log('state.nonce:', nonce);

            console.log('message.prepareMessage():', message.prepareMessage());

            const signature = await signMessageAsync({
                message: message.prepareMessage(),
            });
            console.log('signature:', signature);

            // Verify signature
            const { data } = await axios.post('/api/v1/auth/wallet/verify', { message, signature });
            console.log({ data });
            if (!data.ok) {
                onError({ error: { message: data.errorMessage } });
            }
            const { newToken } = data;

            setState((x) => ({ ...x, loading: false }));
            onSuccess({ address: theAddress, newToken });
        } catch (error) {
            console.error(error);
            onError({ error });
        }
    }
}
