// import { WagmiConfig, createClient, configureChains } from 'wagmi';
// import { mainnet } from 'wagmi/chains';
// import { alchemyProvider } from 'wagmi/providers/alchemy';
// import { publicProvider } from 'wagmi/providers/public';

// import { EthereumClient, w3mConnectors } from '@web3modal/ethereum';
// import { Web3Modal } from '@web3modal/react';
import { WagmiConfig } from 'wagmi';
import config from './components/wagmi/wagmiConfig';


import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Profile from './Profile';

window.Buffer = window.Buffer || require('buffer').Buffer;

// // For Wagmi: Configure chains and project for connectors
// const wcProjectId = '2dafcd93a5165eec6c977d1f57bb91bf';
// const defaultChains = [mainnet];
// // Configure chains & providers with the Alchemy provider.
// // Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
// const { chains, provider, webSocketProvider } = configureChains(defaultChains, [
//     alchemyProvider({ apiKey: 'SKBdGNq7Q7SYhtXdz7ou8_3mBMHD4Nx2' }),
//     publicProvider(),
// ]);

// // Set up client
// const wagmiClient = createClient({
//     autoConnect: true,
//     connectors: [
//         ...new w3mConnectors({
//             version: 1,
//             chains: defaultChains,
//             projectId: wcProjectId,
//         }),
//     ],
//     provider,
//     webSocketProvider,
// });
// const ethereumClient = new EthereumClient(wagmiClient, defaultChains);

function App() {
    return (
        <>
            <WagmiConfig config={config}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/wallet/connect" element={<Profile type="connect" />} />
                        <Route path="/wallet/signin" element={<Profile type="signin" />} />
                        <Route
                            path="/social-link/transfer-confirm"
                            element={<Profile type="signin" />}
                        />
                    </Routes>
                </BrowserRouter>
            </WagmiConfig>
            {/* <Web3Modal
                themeMode={'light'}
                themeVariables={{ '--w3m-z-index': 1000 }}
                projectId={wcProjectId}
                ethereumClient={ethereumClient}
            /> */}
        </>
    );
}

export default App;
